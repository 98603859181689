@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	:root {
		--font-sans: Poppins, sans-serif;
		/* --font-mono: here if you got it... */

		/* prefixed with foreground because it should look good on the background */
		--foreground-destructive: 345 82.7% 40.8%;

		--background: 0 0% 100%;
		--foreground: 222.2 84% 4.9%;

		--muted: 210 40% 93%;
		--muted-foreground: 215.4 16.3% 30%;

		--popover: 0 0% 100%;
		--popover-foreground: 222.2 84% 4.9%;

		--card: 0 0% 100%;
		--card-foreground: 222.2 84% 4.9%;

		--border: 214.3 31.8% 91.4%;
		--input: 214.3 31.8% 91.4%;
		--input-invalid: 0 84.2% 60.2%;

		--primary: 215 95% 36%;
		--primary-foreground: 210 40% 98%;

		--secondary: 210 20% 83%;
		--secondary-foreground: 215 95% 36%;

		--accent: 210 40% 90%;
		--accent-foreground: 215 95% 36%;

		--destructive: 0 70% 50%;
		--destructive-foreground: 210 40% 98%;

		--ring: 215 20.2% 65.1%;

		--radius: 0.5rem;

		--headings: 0 0% 11%;

		--errors: 0, 95%, 36%;

		--body-text: 227, 7%, 16%;
	}

	.dark {
		--background: 222.2 84% 4.9%;
		--foreground: 210 40% 98%;

		/* prefixed with foreground because it should look good on the background */
		--foreground-destructive: -4 84% 60%;

		--muted: 217.2 32.6% 12%;
		--muted-foreground: 215 20.2% 65.1%;

		--popover: 222.2 84% 4.9%;
		--popover-foreground: 210 40% 98%;

		--card: 222.2 84% 4.9%;
		--card-foreground: 210 40% 98%;

		--border: 217.2 32.6% 17.5%;
		--input: 217.2 32.6% 17.5%;
		--input-invalid: 0 62.8% 30.6%;

		--primary: 210 40% 98%;
		--primary-foreground: 222.2 47.4% 11.2%;

		--secondary: 217.2 20% 24%;
		--secondary-foreground: 210 40% 98%;

		--accent: 217.2 32.6% 10%;
		--accent-foreground: 210 40% 98%;

		--destructive: 0 60% 40%;
		--destructive-foreground: 0 85.7% 97.3%;

		--ring: 217.2 32.6% 60%;
	}
}

html,
body {
	font-family: var(--font-sans);
}

.bg-landing {
	background:
		url(/img/backgrounds/landing.webp) center / cover no-repeat,
		linear-gradient(45deg, #012c66 0%, #054eb1 0%) !important;
	@media screen and (min-width: 768px) {
		background:
			url(/img/backgrounds/landing-desktop.webp) center / cover no-repeat,
			linear-gradient(45deg, #012c66 0%, #054eb1 0%) !important;
	}
}
.bg-login {
	background:
		linear-gradient(45deg, rgba(1, 44, 102, 0.8) 0%, rgba(5, 78, 177, 0.8) 0%),
		url(/img/backgrounds/login.webp) 70% 50% / cover no-repeat !important;
}
.bg-how-it-works {
	background:
		linear-gradient(45deg, rgba(1, 44, 102, 0.8) 0%, rgba(5, 78, 177, 0.8) 0%),
		url(/img/backgrounds/how-it-works.webp) center 60% / cover no-repeat !important;
}
.bg-about {
	background:
		linear-gradient(45deg, rgba(1, 44, 102, 0.8) 0%, rgba(5, 78, 177, 0.8) 0%),
		url(/img/backgrounds/about.webp) center 40% / cover no-repeat !important;
}
.bg-pricing {
	background:
		linear-gradient(45deg, rgba(1, 44, 102, 0.8) 0%, rgba(5, 78, 177, 0.8) 0%),
		url(/img/backgrounds/pricing.webp) center 35% / cover no-repeat !important;
}
.bg-contact {
	background:
		linear-gradient(45deg, rgba(1, 44, 102, 0.8) 0%, rgba(5, 78, 177, 0.8) 0%),
		url(/img/backgrounds/contact.webp) 55% 35% / cover no-repeat !important;
}
.bg-cleaning-providers {
	background:
		linear-gradient(45deg, rgba(1, 44, 102, 0.8) 0%, rgba(5, 78, 177, 0.8) 0%),
		url(/img/backgrounds/cleaning-providers.webp) center 25% / cover no-repeat !important;
}
.bg-b2b {
	background:
		linear-gradient(45deg, rgba(1, 44, 102, 0.8) 0%, rgba(5, 78, 177, 0.8) 0%),
		url(/img/backgrounds/b2b.webp) center / cover no-repeat !important;
}
.bg-b2c {
	background:
		linear-gradient(45deg, rgba(1, 44, 102, 0.8) 0%, rgba(5, 78, 177, 0.8) 0%),
		url(/img/backgrounds/b2c.webp) center 80% / cover no-repeat !important;
}
.bg-insurance {
	background:
		linear-gradient(45deg, rgba(1, 44, 102, 0.8) 0%, rgba(5, 78, 177, 0.8) 0%),
		url(/img/backgrounds/insurance.webp) center 90% / cover no-repeat !important;
}
.bg-legal {
	background:
		url(/img/backgrounds/legal.webp) center 90% / cover no-repeat,
		linear-gradient(
			45deg,
			rgba(1, 44, 102, 0.8) 0%,
			rgba(5, 78, 177, 0.8) 0%
		) !important;
}

.shadow-dashboard {
	box-shadow:
		0px 1px 2px 0px rgba(42, 59, 81, 0.12),
		0px 0px 0px 1px rgba(18, 55, 105, 0.08);
}

@media (max-width: 1024px) {
	input[type='time'] {
		padding-left: 44px;
	}

	input[type='date'] {
		padding-left: 29px;
	}
}

@media (max-width: 768px) {
	.text-align-last-center,
	.text-align-last-center select,
	.text-align-last-center option {
		text-align-last: center;
	}
}

select {
	-webkit-appearance: none;
}
